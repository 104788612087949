@font-face {
  font-family: "Poppins-Light";
  src: url('./fonts/Poppins-Light.ttf'), format('truetype');
}
@font-face {
  font-family: "Poppins-Regular";
  src: url('./fonts/Poppins-Regular.ttf'), format('truetype');
}
@font-face {
  font-family: "Poppins-Medium";
  src: url('./fonts/Poppins-Medium.ttf'), format('truetype');
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url('./fonts/Poppins-SemiBold.ttf'), format('truetype');
}
body {margin: 0;padding: 0;}
a {
  
  text-decoration: none;
  cursor: pointer;
}


.blur-input{
  background: rgba(226,108,71,1);
}
.update-profile{
  text-align: center;
  padding-top: 20px;
}
header {
  padding: 15px 5px;
  background-color: #fff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.18);
}
nav {
  background-color: #fff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.18);
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.nav-link {
  font-family: "Poppins-Regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #4b4b4b;
}
.btn-theme {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  border-color: transparent;
}
.btn-theme:hover {
  color: #fff;
}
.maleActiveclass {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  background: rgb(244, 170, 148);
  background: linear-gradient(0deg, rgb(193, 54, 11) 0%, rgb(230, 57, 120) 100%);
  border-color: transparent;
}
.maleActiveclass:hover {
  color: #fff;
}
.femaleActiveClass {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  background: rgb(97, 55, 43);
  background: linear-gradient(0deg, rgb(158, 80, 56) 0%, rgba(204,70,120,1) 100%);
  border-color: transparent;
}
.femaleActiveClass:hover {
  color: #fff;
}
section {
  display: block;
  padding: 50px 0;
  margin: 0;
}
.banner {
  min-height: 653px;
}
#banner {
  position: relative;
  margin: 0;
  padding: 0;
}
#banner .carousel-inner {
  z-index: 0;
}
.img-banner {
  width: 100%;
  height: 100vh;
  display: block;
  object-fit: cover;
}
.banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
h1 {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif !important;
  font-size: 45px !important;
  font-weight: 550 !important;
  color: #fff !important;
  line-height: 56px !important;
  text-align: center !important;
  display: block;
  margin: 180px 0 30px 0 !important;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.45) !important;
}

.accordionHeading {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif !important;
  font-size: 45px !important;
  font-weight: 550 !important;
  color: #fff !important;
  line-height: 56px !important;
  text-align: center !important;
  display: block;
  margin: 180px 0 30px 0 !important;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.45) !important;
}
h1 span {
  display: block;
  font-family: "Poppins-Regular", Arial, Helvetica, sans-serif;
  font-weight: 500;
}





.search-box {
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  display: block;
  padding: 20px;
  box-shadow: 5px 5px 32px rgba(0, 0, 0, 0.28);
  margin: 0 auto;
}
.search-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
.searchBar {
  position: relative;
  width: 80%;
  margin-right: 2%;
}
/* .search-area input[type="text"] {
  position: relative;
} */
.searchBar:after {
  content: '\F52A';
  position: absolute;
  right: 5px;
  top: 5px;
  font-family: 'bootstrap-icons';
  font-size: 18px;
  color: #939393;
  background-color: #fff;
  padding: 0 5px;
}
.search-area input[type="submit"] {
  width: 20%;
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 550;
  color: #fff;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}

.autocomplete-dropdown-container{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  overflow-y: auto;
  max-height: 250px;
}

.autocomplete-dropdown-container span {
  position: relative !important;
  font-size: 14px !important;
  line-height: 18px !important;
margin: 0 10px;
}
.category {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.category li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  text-align: left;
}
.category li a {
  display: block;
  font-family: 'Poppins', Arial, Helvetica, sans-serif ;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  line-height: 30px;

}

.category li a:hover {
  display: block;
  background-color: rgba(226,108,71,1);
  color: white !important;
  cursor: pointer;
  border-radius: 5px;

}
.category li ul {
  margin: 0;
padding: 0;
}
.category li ul li {
  font-family: 'Poppins', Arial, Helvetica, sans-serif ;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  line-height: 24px;
  text-transform: capitalize;
  cursor: pointer;
}

.category li ul li a::before {
  content: '\F2EA';
  font-family: "bootstrap-icons";
}
.autocomplete-dropdown-container span:hover{
  color: rgba(226,108,71,1) !important;

}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #c33268;
  outline: 0;
  box-shadow: none;
}
.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  padding: 0;
  flex: auto;
  transition: all ease-in 0.3s;
}
.services li {
  position: relative;
  list-style: none;
  margin: 0 32px 0 0;
  padding: 20px 10px;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  text-align: center;
  text-decoration: none;
  transition: all ease-in 0.3s;
}
.services li a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: all ease-in 0.3s;
}
.services li:last-child {
  margin-right: 0;
}
.services li img {
  max-width: 100%;
  height: 30px;
  margin: 20px 0 10px 0;
}
.services li p {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 550;
  color: #828282;
  display: block;
  margin: 0;
  text-decoration: none;
  line-height: 15px;
  margin: 0 10px;
}
.services li:hover {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.services li:nth-child(1):hover img {
  content: url('../../public/images/icons/pet-day-care-hover.png')
}
.services li:nth-child(2):hover img {
  content: url('../../public/images/icons/dog-walk-hover.png')
}
.services li:nth-child(3):hover img {
  content: url('../../public/images/icons/boarding-hover.png')
}
.services li:nth-child(4):hover img {
  content: url('../../public/images/icons/dog-training-hover.png')
}
.services li:nth-child(5):hover img {
  content: url('../../public/images/icons/dog-grooming-hover.png')
}
.services li:nth-child(6):hover img {
  content: url('../../public/images/icons/house-visiting-hover.png')
}
.services li:hover p {
  color: #fff;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
h2 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 40px;
  color: #606060;
  font-weight: 600;
  display: block;
  text-align: center;
  margin: 0 0 20px;
}
.servicesBox {
  display: block;
  padding: 30px;
  margin: 0 0 30px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.18);
}
.servicesBox img {
  width: 100%;
  height: 172px;
  display: block;
  border-radius: 10px;
  margin: 0 0 15px;
  object-fit: cover;
}
.servicesBox .titleArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px 0 0 0;
  padding: 0;
}
.servicesBox .title {
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-weight: 600;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.servicesBox .title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgb(226,108,71);
  background: linear-gradient(90deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.servicesBox .rating {
  display: inline-block;
  float: right;
}
.servicesBox .rating i {
  color: #f8c323;
  margin: 0 0 0 4px;
}
p.info {
  display: block;
  font-family: "Poppins-Regular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #4b4b4b;
  line-height: 20px;
  margin: 0 0 10px;
}
.services-footer span {
  display: block;
  font-family: "Poppins-SemiBold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #626262;
  text-align: left;
  margin: 0 0 10px;
}
.services-footer img {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}
.btn-link {
  font-family: "Poppins-SemiBold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0d85e4;
  text-decoration: none;
}
.video-container {
  position: relative;
  margin: 0;
  padding: 0;
  height: 551px;
}
.video-text {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 58px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.video-text span {
  font-weight: 600;
}
.video-text span.underline {
  border-bottom: 4px solid #fff;
}
.play-btn {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.bg-fff8f6 {
  background-color: #fff8f6;
}
h3 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #606060;
  display: block;
  text-align: center;
  margin: 0 0 20px;
}
.client-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px 0;
  padding: 0;
}
.outer-circle {
  position: relative;
  width: 296px;
  height: 296px;
  border-radius: 50%;
  background: rgb(226,108,71);
  background: linear-gradient(30deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  margin: 0;
}
.outer-circle img {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 288px;
  height: 288px;
  border-radius: 50%;
  object-fit: cover;
}
.client-box .test-content {
  display: block;
  margin: 0 0 0 0;
  padding: 8% 0;
}
.test-content .name {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #606060;
  text-align: left;
  display: block;
  margin: 0 0 20px;
}
.test-content p {
  width: 90%;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  text-align: left;
  display: block;
  line-height: 20px;
}
#testimonials .carousel-control-prev {
  left: -20%;
}
#testimonials .carousel-control-next {
  right: -15%;
}

#testimonials .carousel-control-prev-icon {
  background-image: url('../../public/images/icons/prev.png');
  width: 56px;
  height: 56px;
}
#testimonials .carousel-control-next-icon {
  background-image: url('../../public/images/icons/next.png');
  width: 56px;
  height: 56px;
}
.accordion-item {
  margin-bottom: 30px;
}
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
  border-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
  border-bottom-left-radius: 0.375rem!important;
  border-bottom-right-radius: 0.375rem!important;
}
.accordion-item .accordion-button {
  border-radius: 0.375rem!important;
}
.accordion-button {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #525252;
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-body {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
}
.blog {
  border: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.20);
  mix-blend-mode: multiply;
  margin-bottom: 30px;
  border-radius: 0.75rem;
}
.blog .card-img-top {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.blog .card-title {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blog .card-text {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
}
footer {
  display: block;
  padding: 50px 0 0 0;
  margin: 0;
  background-color: #222;
}
.ft-text {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}
.ft-title {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 550;
  color: #fff;
  line-height: 20px;
  display: block;
}
.ft-links {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.ft-links li {
  list-style: none;
  margin: 0 0 12px;
  padding: 0;
}
.ft-links li a {
  font-family: "Poppins-Light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
}
.slinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.slinks li {
  list-style: none;
  margin: 0 8px 0 0;
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: #fff8f6;
  border-radius: 50%;
  text-align: center;
  transition: all ease-in 0.3s;
}
.slinks li:last-child {
  margin-right: 0;
}
.slinks li a {
  color: #222;    
  text-align: center;
  line-height: 28px;
}
.slinks li:hover a {
  color: #fff;
  display: block;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  border-radius: 50%;
}
footer hr {
  width: 100%;
  height: 2px;
  background-color: #656565;
  margin: 30px 0 0 0;
}
p.label {
  font-family: "Poppins-Light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: left;
}
p.label a {
  color: #fff;
  text-decoration: none;
}
.modal-header {
  display: block;
}
.modal-logo {
  max-width: 100%;
  height: 30px;
  margin: 34px auto;
}
.modal-body h4 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #606060;
  display: block;
}
.fb-btn {
  background-color: #3b5897;
  font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: block;
  border-radius: 5px;
  line-height: 38px;
}
.fb-btn i {
  font-size: 20px;
  line-height: 38px;
  margin-right: 5px;
}
.fb-btn:hover, .fb-btn:focus {
  color: #fff;
  text-decoration: none;
}

.w-10 {
  width: 10%!important;
}
.w-80 {
  width: 80%!important;
}
.w-15 {
  width: 15%!important;
}

.g-btn {
  background-color: #f16262 !important;
  font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: block;
  border-radius: 5px;
  line-height: 38px;
}
.g-btn i {
  font-size: 20px;
  line-height: 38px;
  margin-right: 5px;
}
.g-btn:hover, .g-btn:focus {
  color: #fff;
  text-decoration: none;
}
.modal-content .divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #676767;
  border: 0;
  opacity: 1;
  margin: 0;
}



.modal-content .divider:after {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #515151;
  text-align: center;
  content: 'or';
  position: absolute;
  top: -22.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 45px;
  background-color: #fff;
  line-height: 45px;
}
.input-group .form-control {
  border-right: 1px solid transparent;
}
.input-group-text {
  background-color: #fff;
  border-color: #ced4da;
  border-left: 1px solid transparent;
  color: #969696;
}
.input-group:hover .form-control, .input-group:focus .form-control {
  background-color: #fff;
  border-color: #c33268;
  border-right: 1px solid transparent;
}
.input-group:hover .input-group-text, .input-group:focus .input-group-text {
  background-color: #fff;
  border-color: #c33268;
  border-left: 1px solid transparent;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
.input-icon {
  position: relative;
}
.input-icon span {
  position: absolute;
  top: 1px;
  right: 4px;
  background-color: #fff;
  color: #939393;
  line-height: 36px;
  padding: 0 10px;
}
h3.search-result {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #606060;
  text-align: left;
  margin: 0;
  padding: 0;
}
.filterCheckbox label input {margin-right: 100px;}

.filterCheckbox {
margin: 0 4px;
background-color: transparent;
border-radius: 4px;
border: 1px solid #b8b8b8;
overflow: hidden;
font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: 500;
color: #515151;
}

.filterCheckbox label {
  cursor: pointer;
line-height: 38px;
}

.filterCheckbox label span {
text-align: center;
display: block;
padding: 0 30px;
}

.filterCheckbox label input {
position: absolute;
display: none;
color: #515151;
}
/* selects all of the text within the input element and changes the color of the text */
.filterCheckbox label input + span{color: #515151;}


/* This will declare how a selected input will look giving generic properties */
.filterCheckbox input:checked + span {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #ffffff;
  text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}
.search-result {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0;
}
.search-result li {
  list-style: none;
  margin: 0 0 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
}
.search-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.search-items img {
  width: 122px;
  height: 122px;
  border-radius: 5px;
  display: inline-block;
  background-color: #222;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  margin: 0 20px 0 0;
}
.search-items .info {
  width: 82%;
  display: inline-block;
}
.search-items .info h4 {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-weight: 600;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 30px;
  margin: 0;
  cursor: pointer;
}
.search-items .info h4 span {
  font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #606060;
  -webkit-text-fill-color: #606060;
  text-align: right;
  float: right;
}
.reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
  line-height: 18px;
}
.reviews i {
  font-size: 15px;
  margin-right: 5px;
  color: #f8c323;
  line-height: 18px;
}
.other-info {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 0;
}
.other-info li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
  line-height: 24px;
  box-shadow: none;
}
.other-info li i {
  font-size: 14px;
  width: 18px;
  text-align: center;
  margin-right: 5px;
}
.amenities {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
}
.amenities span {
  font-size: 13px;
  color: #dc6254;
}
.border-start {
  border-color: #ababab;
}

.related-centres {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.related-centres li {
  display: block;
  margin: 0 0 30px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}
.related-centres li img {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background-color: #222;
  display: inline-block;
  margin: 0 10px 0 0;
  object-fit: cover;
}
.related-centres li .info {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.related-centres li .info h4 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: auto!important;
  display: block;
  margin: 0 0 4px;
}
.related-centres li .info .review {
  display: block;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  font-weight: 400;
}
.related-centres li .info .review i {
  color: #f8c323;
  margin: 0 5px 0 0;
  font-size: 14px;
}

.pagination .page-link {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #9e9e9e;
  border: 0;
}
.pagination .active>.page-link, .page-link.active {
  color: #fff;
  border-radius: 5px;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.pagination .page-link:hover, .pagination .page-link:focus {
  color: #fff;
  border-radius: 5px;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}

h2.signup {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #606060;
  text-align: left;
}
.list {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0;
  list-style: none;
}
.list li {
  position: relative;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
  padding: 0 0 0 30px;
  margin: 0 0 10px;
  text-align: left;
}
.list li:last-child {
  margin-bottom: 0;
}
.list li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 15px;
  height: 11px;
  /* background: url('../images/tickmark.png') center no-repeat; */
}
.signup-body {
  display: block;
  padding: 20px;
}
.divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #676767;
  border: 0;
  opacity: 1;
  margin: 0;
}
.divider:after {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #515151;
  text-align: center;
  content: 'or';
  position: absolute;
  top: -22.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 45px;
  background-color: #fff;
  line-height: 45px;
}
#signup legend {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #606060;
  display: block;
  text-align: left;
}
@media (max-width: 767px) {
  .desktop {
      display: none;
  }
  .mobile {
      display: block;
  }
  .banner {
      min-height: auto;
  }
  .img-banner {
      height: 450px;
  }
  .banner-content {
      padding: 30px 0;
  }
 
  .searchBar {
      width: 75%;
  }
  .searchBar:after {
      display: none;
  }
  .search-area input[type="submit"] {
      width: 25%;
  }
  .services li {
      margin: 0 16px 16px 0;
      padding: 10px;
      width: 30%;
      height: 80px;
  }
  .services li:nth-child(3) {
      margin-right: 0;
  }
  .services li:nth-child(6) {
      margin-right: 0;
  }
  .services li img {
      margin: 10px 0;
  }
  .services li p {
      font-size: 12px;
      font-weight: 300;
  }
  .video-container {
      height: 360px;
  }
  .video-text {
      font-size: 36px;
  }
  h2 {
      font-size: 24px;
  }
  p.label {
      text-align: center;
      line-height: 24px;
  }
  .outer-circle {
      width: 148px;
      height: 148px;
      margin: 0 30%;
  }
  .outer-circle img {
      width: 140px;
      height: 140px;
  }
  #testimonials .carousel-control-prev {
      left: -20% ;
  }
  #testimonials .carousel-control-next {
      right: -15% ;
  }
  .test-content .name {
      text-align: center;
  }
  .client-box .test-content {
      margin: 0 15px;
  }
  .test-content p {
    width: 90%;
    font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4b4b4b;
    text-align: left;
    display: block;
    line-height: 20px;
  }
}

#testimonials .carousel-control-prev {
  left: -20%;
}
#testimonials .carousel-control-next {
  right: -15%;
}
#testimonials .carousel-control-prev-icon {
  background-image: url('../../public/images/icons/prev.png');
  width: 56px;
  height: 56px;
}
#testimonials .carousel-control-next-icon {
  background-image: url('../../public/images/icons/next.png');
  width: 56px;
  height: 56px;
}
.accordion-item {
  margin-bottom: 30px;
}
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
  border-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
  border-bottom-left-radius: 0.375rem!important;
  border-bottom-right-radius: 0.375rem!important;
}
.accordion-item .accordion-button {
  border-radius: 0.375rem!important;
}
.accordion-button {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #525252;
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-body {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
}
.blog {
  border: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.20);
  mix-blend-mode: multiply;
  margin-bottom: 30px;
  border-radius: 0.75rem;
}
.blog .card-img-top {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.blog .card-title {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blog .card-text {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
}
footer {
  display: block;
  padding: 50px 0 0 0;
  margin: 0;
  background-color: #222;
}
.ft-text {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}
.ft-title {
  font-family: "Poppins-Medium", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 550;
  color: #fff;
  line-height: 20px;
  display: block;
}
.ft-links {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.ft-links li {
  list-style: none;
  margin: 0 0 12px;
  padding: 0;
}
.ft-links li a {
  font-family: "Poppins-Light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
}
.slinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.slinks li {
  list-style: none;
  margin: 0 8px 0 0;
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: #fff8f6;
  border-radius: 50%;
  text-align: center;
  transition: all ease-in 0.3s;
  line-height: 28px;
}
.slinks li:last-child {
  margin-right: 0;
}
.slinks li a {
  color: #222;    
  text-align: center;
}
.slinks li:hover a {
  color: #fff;
  display: block;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  border-radius: 50%;
}
footer hr {
  width: 100%;
  height: 2px;
  background-color: #656565;
  margin: 30px 0 0 0;
}
p.label {
  font-family: "Poppins-Light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: left;
}
p.label a {
  color: #fff;
  text-decoration: none;
}
.modal-header {
  display: block;
}
.modal-logo {
  max-width: 100%;
  height: 30px;
  margin: 34px auto;
}
.modal-body h4 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #606060;
  display: block;
}
.fb-btn {
  background-color: #3b5897;
  font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: block;
  border-radius: 5px;
  line-height: 38px;
}
.fb-btn i {
  font-size: 20px;
  line-height: 38px;
  margin-right: 5px;
}
.fb-btn:hover, .fb-btn:focus {
  color: #fff;
  text-decoration: none;
}
.g-btn {
  background-color: #f16262;
  font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: block;
  border-radius: 5px;
  line-height: 38px;
}
.g-btn i {
  font-size: 20px;
  line-height: 38px;
  margin-right: 5px;
}
.g-btn:hover, .g-btn:focus {
  color: #fff;
  text-decoration: none;
}
.modal-content .divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #676767;
  border: 0;
  opacity: 1;
  margin: 0;
}
.modal-content .divider:after {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #515151;
  text-align: center;
  content: 'or';
  position: absolute;
  top: -22.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 45px;
  background-color: #fff;
  line-height: 45px;
}
.input-group .form-control {
  border-right: 1px solid transparent;
}
.input-group-text {
  background-color: #fff;
  border-color: #ced4da;
  border-left: 1px solid transparent;
  color: #969696;
}
.input-group:hover .form-control, .input-group:focus .form-control {
  background-color: #fff;
  border-color: #c33268;
  border-right: 1px solid transparent;
}
.input-group:hover .input-group-text, .input-group:focus .input-group-text {
  background-color: #fff;
  border-color: #c33268;
  border-left: 1px solid transparent;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
.input-icon {
  position: relative;
}
.input-icon span {
  position: absolute;
  top: 1px;
  right: 4px;
  background-color: #fff;
  color: #939393;
  line-height: 36px;
  padding: 0 10px;
}
h3.search-result {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #606060;
  text-align: left;
  margin: 0;
  padding: 0;
}
.filterCheckbox label input {margin-right: 100px;}

.filterCheckbox {
margin: 0 4px;
background-color: transparent;
border-radius: 4px;
border: 1px solid #b8b8b8;
overflow: hidden;
font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: 500;
color: #515151;
}

.filterCheckbox label {
  cursor: pointer;
line-height: 38px;
}

.filterCheckbox label span {
text-align: center;
display: block;
padding: 0 30px;
}

.filterCheckbox label input {
position: absolute;
display: none;
color: #515151;
}
/* selects all of the text within the input element and changes the color of the text */
.filterCheckbox label input + span{color: #515151;}


/* This will declare how a selected input will look giving generic properties */
.filterCheckbox input:checked + span {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #ffffff;
  text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}
.search-result {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0;
}
.search-result li {
  list-style: none;
  margin: 0 0 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
}
.search-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.search-items img {
  width: 122px;
  height: 122px;
  border-radius: 5px;
  display: inline-block;
  background-color: #222;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  margin: 0 20px 0 0;
}
.search-items .info {
  width: 82%;
  display: inline-block;
}
.search-items .info h4 {
  font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-weight: 600;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 30px;
  margin: 0;
}
.search-items .info h4 span {
  font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #606060;
  -webkit-text-fill-color: #606060;
  text-align: right;
  float: right;
}
.reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
  line-height: 18px;
}
.reviews i {
  font-size: 15px;
  margin-right: 5px;
  color: #f8c323;
  line-height: 18px;
}
.other-info {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 0;
}
.other-info li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
  line-height: 24px;
  box-shadow: none;
}
.other-info li i {
  font-size: 14px;
  width: 18px;
  text-align: center;
  margin-right: 5px;
}
.amenities {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  text-align: left;
}
.amenities span {
  font-size: 13px;
  color: #dc6254;
}
.border-start {
  border-color: #ababab;
}
.related-centres {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.related-centres li {
  display: block;
  margin: 0 0 30px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}
.related-centres li img {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background-color: #222;
  display: inline-block;
  margin: 0 10px 0 0;
  object-fit: cover;
}
.related-centres li .info {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.related-centres li .info h4 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: auto!important;
  display: block;
  margin: 0 0 4px;
}
.related-centres li .info .review {
  display: block;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #515151;
  font-weight: 400;
}
.related-centres li .info .review i {
  color: #f8c323;
  margin: 0 5px 0 0;
  font-size: 14px;
}
.pagination .page-link {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #9e9e9e;
  border: 0;
}
.pagination .active>.page-link, .page-link.active {
  color: #fff;
  border-radius: 5px;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.pagination .page-link:hover, .pagination .page-link:focus {
  color: #fff;
  border-radius: 5px;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.bg-fff8f6 {
  background-color: #fff8f6;
}
.head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.head img {
  width: 92px;
  height: 92px;
  border-radius: 5px;
  background-color: #222;
  object-fit: cover;
  margin: 0 20px 0 0;
}
.head .details {
  display: inline-block;
  margin: 0;
  padding: 10px 0;
  vertical-align: top;
}
.head .details h5 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 36px;
  color: #cc4678;
  /* background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  display: block;
}
.head .details h5 span {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #515151!important;
  margin-left: 10px;
}
.head .details h5 span i {
  color: #f8c323!important;
}
.head .details ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
}
.head .details ul li {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  margin-left: 12px;
}
.head .details ul li:first-child {
  margin-left: 0;
}
.head .details ul li i {
  margin-right: 5px;
}
.grid {
  display: flex;
  flex-direction: row;
  margin: 15px 0 0 0;
  padding: 0;
  list-style: none;
  flex: auto;
}
.grid li:nth-child(1) {
  width: 60%;
  margin: 0 1% 0 0;
}
.grid li:nth-child(1) img {
  /* width: 100%;
  height: 345px; */
  display: block;
  border-radius: 5px;
  object-fit: cover;
}
.grid li:nth-child(2) {
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grid li:nth-child(2) a {
  width: 48%;
  margin: 0 1% 6px 1%;
}
.grid li:nth-child(2) a img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
}
.details-tab.nav-pills {
  border: 0;
  border-bottom: 1px solid #676767;
}
.details-tab.nav-pills .nav-link {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #525252;
}
.details-tab.nav-pills .nav-link:focus, .details-tab .nav-pills .nav-link:hover {
  border: 0;
  border-bottom: 1px solid #676767;
}
.details-tab.nav-pills .nav-link.active {
  position: relative;
  background-color: transparent;
  border-radius: 0;
  color: #525252;
  font-weight: 600;
  border: 0;
}
.details-tab.nav-pills .nav-link.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 142px;
  height: 3px;
  background-color: #cc4678;
}
.section-title {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #606060;
  display: block;
  margin: 0 0 20px;
}
.section-title span {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #515151;
  margin-left: 10px;
}
.section-title span i {
  font-size: 18px;
  color: #f8c323;
}
p.section-text {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
  margin: 0 0 20px;
}
.heading {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #606060;
  line-height: 24px;
  margin: 0 0 20px;
}
.ea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: auto;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  text-align: center;
  vertical-align: middle;
}
.ea li {
  position: relative;
  min-width: 120px;
  max-width: 140px;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  text-align: center;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #cc4678;
  padding: 18px;
  margin: 0 12px 60px 0;
}
.ea li img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 10px auto;
}
.ea li .price {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #cc4678;
  text-align: center;
}
.booking-form.card {
  border: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
}
.booking-form .card-header {
  background-color: #fff;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  color: #606060;
}
.booking-form .card-header span {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #cc4678;
  text-align: right;
}
.reviews-grid {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0;
  list-style: none;
}
.reviews-grid li {
  padding: 12px;
  margin: 0 0 30px;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-flow: row;
}
.reviews-grid li:last-child {
  margin-bottom: 0;
}
.reviews-grid li img {
  display: inline-block;
  width: 82px;
  height: 82px;
  border-radius: 5px;
  margin: 0 15px 0 0;
  object-fit: cover;
}
.reviews-grid li .desc {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.reviews-grid li h6 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #525252;
  margin: 0 0 5px;
  display: block;
}
.reviews-grid li h6 i {
  margin-left: 5px;
  color: #f8c323;
}
.reviews-grid li p {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #4b4b4b;
  line-height: 20px;
  display: inline-block;
}
.cart-services {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
.cart-services li {
  display: inline-block;
  padding: 15px;
  margin: 0;
}
.cart-services li {
  border-bottom: 1px dashed #cc4678;
}
.cart-services li:last-child {
  border-bottom: none;
}
.cart-services li p {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #606060;
  text-align: left;
  display: inline-block;
  margin: 0;
}
.cart-services li p span {
  display: block;
  font-weight: 500;
}
.cart-services li a {
  float: right;
}
.cart-item {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: middle;
}
.cart-item img {
  width: 92px;
  height: 92px;
  border-radius: 5px;
  background-color: #222;
  object-fit: cover;
  margin: 0 10px 0 0;
}
.cart-item .cart-item-name {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #cc4678;
  /* background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-align: left;
  line-height: 30px;
  margin-top: 16px;
}
.cart-item .cart-item-name span {
  display: block;
  text-align: left;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  line-height: 22px;
}
.cart-item .cart-item-name span i {
  color: #f8c323!important;
}
.cart-item .cart-item-name span a {
  color: #515151;
}
.service-head {
  position: relative;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #606060;
  display: block;
  line-height: 30px;
  margin: 0 -1rem;
  padding: 0 1rem;
}
.service-head:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  background-color: #cecece;
}
.price-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 15px -1rem;
  padding: 0 1rem;
  list-style: none;
}
.price-list li {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  display: block;
  line-height: 30px;
  margin-bottom: 10px;
}
.price-list li span {
  text-align: right;
  float: right;
  font-weight: 500;
}
.price-list li:last-child {
  margin-bottom: 0;
  font-weight: 700;
}
.price-list li:last-child span {
  font-weight: 700;
}
.price-list:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  background-color: #cecece;
}
.total-price {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 30px;
  display: block;
}
.total-price span {
  text-align: right;
  float: right;
}
.nav-user {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
}
.w-40 {
  width: 40%!important;
}
.box-shadow {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
}
aside {
  display: block;
  margin: 0 0 30px 0;
  padding: 20px;
  border-radius: 5px;
}
.user-header {
  display: block;
  margin: 0;
  padding: 20px;
  text-align: center;
}
.user-header .user-pic {
  position: relative;
  width: 142px;
  height: 142px;
  margin: 0 auto;
  padding: 0;
}
.user-header .user-pic .edit {
  position: absolute;
  bottom: 22px;
  right: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  font-size: 12px;
  line-height: 22px;
  border: transparent;
}
.user-header .user-pic img {
  width: 142px;
  height: 142px;
  border-radius: 50%;
  border: 1px solid #cc4678;
}
.user-header .username {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #606060;
  line-height: 36px;
}
h2.signup {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #606060;
  text-align: left;
}
.list {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0;
  list-style: none;
}
.list li {
  position: relative;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  line-height: 20px;
  padding: 0 0 0 30px;
  margin: 0 0 10px;
  text-align: left;
}
.list li:last-child {
  margin-bottom: 0;
}
.list li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 15px;
  height: 11px;
  background: url('../../public/images/tickmark.png') center no-repeat;
}
.signup-body {
  display: block;
  padding: 20px;
}
.divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #676767;
  border: 0;
  opacity: 1;
  margin: 0;
}
.divider:after {
  font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #515151;
  text-align: center;
  content: 'or';
  position: absolute;
  top: -22.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 45px;
  background-color: #fff;
  line-height: 45px;
}
#signup legend {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #606060;
  display: block;
  text-align: left;
}
.sidenav.nav-pills li {
  margin-bottom: 5px;
}
.sidenav.nav-pills li:last-child {
  margin-bottom: 0;
}
.sidenav.nav-pills .nav-link {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  cursor: pointer;
}
.sidenav.nav-pills .nav-link i {
  margin-right: 10px;
}
.sidenav.nav-pills .nav-link:hover, .sidenav.nav-pills .nav-link:focus {
  color: #d14e6d;
  background-color: #fff8f6;
}
.sidenav.nav-pills .nav-link.active {
  color: #d14e6d;
  background-color: #fff8f6;
}
.dashboard-title {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #606060 !important;
  text-align: left !important;
  line-height: 40px !important;
  display: block !important;
}
.dashboard-title span {
  font-size: 16px !important;
  font-weight: 500 !important;
  float: right !important;
  text-align: right !important;
}
.w-10 {
  width: 10%!important;
}
.w-80 {
  width: 80%!important;
}
.w-15 {
  width: 15%!important;
}
.profile-data>tbody>tr>td:first-child {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  text-align: left;
}
.profile-data>tbody>tr>td:last-child {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #606060;
  text-align: left;
}
#orders-tab.nav-pills {
  border-bottom: 1px solid #c8c8c8;
}
#orders-tab.nav-pills .nav-link.active, #orders-tab.nav-pills .show>.nav-link {
  background-color: transparent;
  background: -webkit-linear-gradient(#e26c47, #cc4678);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
#orders-tab.nav-pills .nav-link {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #606060;
  line-height: 54px;
  position: relative;
}
#orders-tab.nav-pills .nav-link.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 118px;
  height: 3px;
  background: rgb(226,108,71);
  background: linear-gradient(90deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}


.orders {
  width: 100%;
  margin: 0 0 30px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.orders > thead > tr {
  border-bottom: 1px solid #c8c8c8;
}
.orders > thead > tr > th {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #606060;
}
.orders > tbody > tr > td > img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  margin: 0 5px 0 0;
  object-fit: cover;
}
.orders > tbody > tr > td {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #606060;
}
.inbox {
  display: flex;
  flex-direction: row;
  margin: 0 0 15px 0;
  padding: 15px;
  border-radius: 8px;
}
.inbox img {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(0,0,0,0.13);
  object-fit: cover;
}
.inbox .notification-text {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #525252;
  margin-left: 10px;
}
.inbox .notification-text span {
  display: inline-block;
  font-weight: 300;
}
.inbox .notification-text span i {
  color: #f8c323;
  margin-right: 5px;
}
.inbox .info {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  width: 25%;
}
.favourites {
  display: flex;
  margin: 0;
  padding: 15px;
  justify-content: space-between;
}
.favourites img {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(0,0,0,0.13);
  object-fit: cover;
  margin-right: 10px;
}
.favourites .favourites-content {
  margin: 0;
  padding: 0;
}
.favourites .favourites-content .cdetails {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.favourites .favourites-content .cdetails .cname {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #525252;
  text-align: left;
  margin-right: 10px;
  line-height: 36px;
}
.favourites .favourites-content .cdetails .creviews {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  line-height: 36px;
}
.favourites .favourites-content .cdetails .creviews i {
  color: #f8c323;
  margin-right: 2px;
}
.otherinfo {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.otherinfo li {
  list-style: none;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #515151;
  text-align: left;
  margin-right: 10px;
}
.favourites .heart {
  color: #d7443e;
  width: 15%;
  text-align: right;
}
.my-pets thead>tr>th {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #606060;
}
.my-pets img {
  width: 78px;
  height: 78px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}
.my-pets {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  line-height: 18px;
}
.ordermodal {
  display: flex;
  margin: 15px 0;
  padding: 0;
}
.ordermodal img {
  width: 56px;
  height: 56px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}
.ordermodal .ordercontent {
  width: 60%;
  margin: 0;
  padding: 0;
}
.ordermodal .ordercontent .ordername {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #d7443e;
}
.otherinfo {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.otherinfo li {
  list-style: none;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #515151;
  text-align: left;
  margin-right: 10px;
}
.ordermodal .orderdate {
  margin: 0;
  padding: 0;
  align-items: flex-end;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  text-align: right;
}
.ordermodal .orderdate span {
  font-weight: 400;
  display: block;
}
.chatbox {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
}
.chatbox .chat-header {
  background-color: #ffe9e7;
  padding: 15px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #525252;
}
.chatbox .chat-header img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
}
.chatbox .chat-body {
  padding: 10px 20px;
  margin: 0;
}
.chatbox .chat-footer {
  padding: 5px 20px 20px 20px;
  margin: 0;
  border-radius: 0 0 5px 5px;
}
.chat-body .chat-module {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 15px;
  min-height: 420px;
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.chat-module .chat-reciever {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: flex-start;
}
.chat-reciever .message {
  position: relative;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 0 8px 8px 8px;
  margin: 0 0 5px 0;
  max-width: 70%;
  display: inline-block;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #4b4b4b;
  line-height: 20px;
}
.chat-reciever .message:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #f7f7f7;
  border-left: 10px solid transparent;
}
.chat-reciever .time {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #4b4b4b;
  display: block;
  line-height: 20px;
}
.chat-module .chat-sender {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: flex-end;
}
.chat-sender .message {
  position: relative;
  background-color: #ffe9e7;
  padding: 10px;
  border-radius: 8px 0 8px 8px;
  margin: 0 0 5px 0;
  max-width: 70%;
  display: inline-block;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #4b4b4b;
  line-height: 20px;
}
.chat-sender .message:after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #ffe9e7;
  border-right: 10px solid transparent;
}
.chat-sender .time {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #4b4b4b;
  display: block;
  line-height: 20px;
}
.group-border {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.text-bg-theme {
  color: #fff!important;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  border-radius: 50%;
}
.vh-100 {
  height: 100vh;
}
.accordion {
  --bs-accordion-btn-icon: url('../../public/images/icons/arrow.svg');
  --bs-accordion-btn-active-icon: url('../../public/images/icons/white-arrow.svg');
  --bs-accordion-btn-icon-transform: rotate(0deg);
}
.accordion-button::after {
  transform: rotate(-180deg);
}
#fillservicedetails .accordion-item {
  margin: 0 0 20px;
}
.sp-bg {
  margin: 0;
  padding: 55px 50px;
  position: relative;
  height: 100vh;
}
.sp-bg:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
.sp-bg-content-box {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: center;
}
.sp-bg-content-box h1 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 54px;
  text-shadow: 2px 1px 2px rgba(0,0,0,0.25);
}
.bottom-content {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.sp-content {
  display: block;
  background-color: #fff;
  padding: 55px 50px;
  margin: 0;
  height: 100vh;
}
.btn-back {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #717171;
}
.sp-content-box {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-direction: column;
}
.sp-content-box h1 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #606060;
  line-height: 48px;
  text-shadow: none;
}
.sp-content-box h1 span {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin: 20px 0 0 0;
}
.sp-checkbox {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 10px 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  border-radius: 8px;
}
.sp-checkbox div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  line-height: 25px;
  transition: .5s ease;
  padding: 0 18px;
  border-radius: 8px;
}
.sp-checkbox div img {
  max-width: 100%;
  height: 34px;
  object-fit: cover;
  margin-right: 15px;
}
.sp-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.sp-checkbox:hover div, .sp-checkbox:focus div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
}
.sp-checkbox input[type=checkbox]:checked~div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
}
.petdaycare:hover div img, .petdaycare input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/pet-day-care-hover.png');
}
.dogwalk:hover div img, .dogwalk input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/dog-walk-hover.png');
}
.dogtraining:hover div img, .dogtraining input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/dog-training-hover.png');
}
.boarding:hover div img, .boarding input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/boarding-hover.png');
}
.doggrooming:hover div img, .doggrooming input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/dog-grooming-hover.png');
}
.housevisiting:hover div img, .housevisiting input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/house-visiting-hover.png');
}
.bath:hover div img, .bath input[type=checkbox]:checked~div img {
  content: url('../../public/images/icons/bath-hover.png');
}
.sp-form {
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 0 10px 0 0;
  max-height: 70vh;
  overflow-y: auto;
}
.form-title {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #606060;
  line-height: 24px;
}
.form-title span {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #979797;
}
::-webkit-scrollbar {
width: 6px;
background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}
::-moz-scrollbar {
width: 6px;
background-color: #F5F5F5;
}
::-moz-scrollbar-thumb {
background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
::-moz-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}
::-ms-scrollbar {
width: 6px;
background-color: #F5F5F5;
}
::-ms-scrollbar-thumb {
background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
::-ms-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}
::-scrollbar {
width: 6px;
background-color: #F5F5F5;
}
::-scrollbar-thumb {
background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
}
::-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}
/* The customcheck */
.customcheck {
  position: relative;
  font-family: 'Poppins', Arial;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  line-height: 12px;
  padding-left: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customcheck input {
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #858585;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.customcheck:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  border-radius: 5px;
  border-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customcheck .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.customradio [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.customradio [type="radio"]:checked + label,
.customradio [type="radio"]:not(:checked) + label
{
  position: relative;
  font-family: 'Poppins', Arial;
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  line-height: 20px;
  padding-left: 24px;
  cursor: pointer;
  display: inline-block;
}
.customradio [type="radio"]:checked + label:before,
.customradio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #858585;
  border-radius: 100%;
  background: #fff;
}
.customradio [type="radio"]:checked + label:after,
.customradio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.customradio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.customradio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.custom-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 28px;
  height: 28px;
  background-color: #fff;
  color: #979797;
  font-size: 20px;
  text-align: center;
}
.amenities-box {
  display: block;
  margin: 0;
  padding: 15px;
}
.amenities-select {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
  flex: auto;
}
.amenities-select li {
  width: 20%;
  margin: 0 20px 20px 0;
  padding: 0;
  list-style: none;
}
.amenities-checkbox {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 120px;
}
.amenities-checkbox div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  line-height: 25px;
  transition: .5s ease;
  padding: 15px 8px;
  text-align: center;
  border-radius: 8px;
}
.amenities-checkbox div img {
  max-width: 100%;
  height: 34px;
  object-fit: cover;
  margin-bottom: 5px;
}
.amenities-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.amenities-checkbox:hover div, .amenities-checkbox:focus div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
  min-height: 120px;
}
.amenities-checkbox input[type=checkbox]:checked~div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
  min-height: 120px;
}
.form-subtitle {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #515151;
}
.set-price {
  display: flex;
  margin: 12px;
  padding: 0;
  flex-direction: column;
}
.set-price li {
  width: 100%;
  margin: 0 0 10px;
  padding: 4px 8px;
  list-style: none;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #6d6d6d;
}
.set-price li span {
  border: 1px solid #b8b8b8;
  padding: 4px 8px;
  border-radius: 5px;
  float: right;
}
.file-upload {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  /* box-shadow: 0 0 7px rgba(0, 0, 0, 0.13); */
  box-sizing: border-box;
  border-radius: 8px;
}
.file-upload div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  line-height: 25px;
  transition: .5s ease;
  padding: 15px 8px;
  text-align: center;
  background-color: #fff8f6;
  border-radius: 8px;
}
.file-upload div i {
  font-size: 24px;
}
.file-upload div img {
  max-width: 100%;
  height: 34px;
  object-fit: cover;
  margin-bottom: 5px;
}
.file-upload input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.file-upload:hover div, .file-upload:focus div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
}
.file-upload input[type=checkbox]:checked~div {
  background: rgb(226,108,71);
  background: linear-gradient(0deg, rgba(226,108,71,1) 0%, rgba(204,70,120,1) 100%);
  color: #fff;
  border-radius: 8px;
  min-height: 120px;
}
.add-service>tbody>tr>td {
  padding: 20px 10px!important;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #515151;
}
.add-service img {
  max-width: 100%;
  height: 40px;
}
h2.error-text {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 90px;
  font-weight: 700;
  color: #222;
  text-align: center;
  margin: 15px 0;
}
h3.error-message {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #222;
  display: block;
  margin: 30px 0;
}
.fs-36 {
  font-size: 36px;
}



@media (max-width: 767px) {
  .desktop {
      display: none;
  }
  .mobile {
      display: block;
  }
  .banner {
      min-height: auto;
  }
  .img-banner {
      height: 450px;
  }
  .banner-content {
      padding: 30px 0;
  }
 
  .searchBar {
      width: 75%;
  }
  .searchBar:after {
      display: none;
  }
  .search-area input[type="submit"] {
      width: 25%;
  }
  .services li {
      margin: 0 16px 16px 0;
      padding: 10px;
      width: 30%;
      height: 80px;
  }
  .services li:nth-child(3) {
      margin-right: 0;
  }
  .services li:nth-child(6) {
      margin-right: 0;
  }
  .services li img {
      margin: 10px 0;
  }
  .services li p {
      font-size: 12px;
      font-weight: 300;
  }
  .video-container {
      height: 360px;
  }
  .video-text {
      font-size: 36px;
  }
  h2 {
      font-size: 24px;
  }
  p.label {
      text-align: center;
      line-height: 24px;
  }
  .outer-circle {
      width: 148px;
      height: 148px;
      margin: 0 30%;
  }
  .outer-circle img {
      width: 140px;
      height: 140px;
  }
  #testimonials .carousel-control-prev {
      left: 0;
  }
  #testimonials .carousel-control-next {
      right: 0;
  }
  .test-content .name {
      text-align: center;
  }
  .client-box .test-content {
      margin: 0 15px;
  }
  .test-content p {
      width: 100%;
  }
  h2.error-text {
    font-size: 60px;
}
h3.error-message {
    font-size: 18px;
    font-weight: 700;
}
}